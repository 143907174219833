import { enableClient } from "@wix/cli-app/internal/platform-sdk/site";
(async function () {
  if (!window['React']) {
    await import('https://unpkg.com/react@16.14.0/umd/react.production.min.js');
    await import('https://unpkg.com/react-dom@16.14.0/umd/react-dom.production.min.js');
  }
  const { default: UsersCustomElementClass } = await import('/src/site/widgets/custom-elements/emoji-bar/element.tsx');

  let accessTokenListenerFn = UsersCustomElementClass.prototype.accessTokenListener;
  if (!accessTokenListenerFn) {
    const { injectGetAccessToken } = enableClient('a13cf90c-3e7c-413e-9b1e-bc430f448373');
    accessTokenListenerFn = injectGetAccessToken;
  }
  Object.defineProperty(UsersCustomElementClass.prototype, 'accessTokenListener', {
    writable: false,
    configurable: false,
    value: accessTokenListenerFn
  });

  customElements.define('emoji-bar', UsersCustomElementClass);
})();